
import {Component, Prop, Vue} from 'nuxt-property-decorator'
import {CarSelectorStore} from './store'
import BlockName from '~/components/BlockName.vue'
import CarSelector from '~/components/car-selector/panels.vue'
// import Category from '~/std/Category'
import svgCar from '~/components/svg/car.vue'

@Component({
  components: {BlockName, CarSelector, svgCar}
})
export default class extends Vue {
  @Prop({default: null}) selected!: any
  @Prop({default: 'w-56'}) width!: string
  @Prop({default: true}) isModal!: boolean
  @Prop({default: null}) mode!: Number | null

  product: any = null

  props = []

  modeCarSelect: any = null

  car = {
    selected: {title: '', description: '', data: [] as any},
    items: [{title: '', description: '', data: []}]
  }

  get classCarSelect() {
    return {
      'car-selector-container-modal': this.isModal,
      'car-selector-container': !this.isModal,
    }
  }

  fetch() {
    this.modeCarSelect = this.mode
    this.init()
  }

  // created() {
  //   this.modeCarSelect = this.mode
  //   this.init()
  // }

  get initAddCar() {
    if (this.modeCarSelect === 1) return this.car.selected
    if (this.selected === null) return null
    const init = {data: [] as any}
    if (this.selected?.manufacturer) {
      init.data.push({key: 'brand', data: {key: this.selected?.manufacturer, slug: this.selected?.manufacturer_code}})
    }
    if (this.selected?.model) {
      init.data.push({key: 'model', data: {key: this.selected?.model, slug: this.selected?.model_code}})
    }
    if (this.selected?.modification) {
      init.data.push({key: 'type', data: {key: this.selected?.modification, slug: this.selected?.modification_code}})
    }
    if (this.selected?.category) {
      init.data.push({key: 'category', data: {id: this.selected?.category}})
    }
    return init;
  }

  init() {
    if (this.selected?.manufacturer_code) {
      this.car.selected.data.push({key: 'brand', data: {key: this.selected?.manufacturer, slug: this.selected?.manufacturer_code}})
    }
    if (this.selected?.model_code) {
      this.car.selected.data.push({key: 'model', data: {key: this.selected?.model, slug: this.selected?.model_code}})
    }
    if (this.selected?.modification_code) {
      this.car.selected.data.push({key: 'type', data: {key: this.selected?.modification, slug: this.selected?.modification_code}})
    }
  }

  changeCarSelected(value: any) {
    const title = value.data.map((e: { data: any; }) => e.data.value).join('-')
    const index = this.car.items.findIndex((e) => e.title === title)
    if (index >= 0) {
      this.car.selected = this.car.items[index]
    }
    this.carStorageRefresh()
    this.modeCarSelect = null
    this.$emit('onChangeCarSelected', this.car.selected)
    this.$root.$emit('onChangeCarSelected', this.car.selected)
  }

  onCarSelected(params: any) {
    const {data, isChanged} = params
    const title = data.map((e: { data: any; }) => e.data.value).filter((e: any)=>e).join('-')
    let index = this.car.items.findIndex((e) => {
      // const ex: any = e.data.find((e: any) => e.key == data[0].key && e.data.key == data[0].data.key)
      // if (!ex) return false
      return e.data.map((v: any) => v.data.key).join('|') === data.map((v: any) => v.data.key).join('|')
    })
    if (!this.modeCarSelect) {
      if (index < 0) {
        this.car.items.push(JSON.parse(JSON.stringify({})))
        index = this.car.items.length - 1
      }
      this.car.items[index].title = title
      this.car.items[index].description = title?.toLowerCase()
      this.car.items[index].data = data
      this.car.selected = this.car.items[index]
    }
    if (!isChanged) {
      this.car.selected.data = data
    }
    if (this.isModal) this.carStorageRefresh()
    if (isChanged) this.modeCarSelect = null
    this.$emit('onChangeCarSelected', this.car.selected)
    this.$root.$emit('onChangeCarSelected', this.car.selected)
  }

  addCarSelected() {
    this.modeCarSelect = 0
  }

  editCarSelected() {
    this.modeCarSelect = 1
  }

  delCarSelected() {
    this.$buefy.dialog.confirm({
      message: this.$t('msg_Delete_NoUndo').toString(),
      confirmText: this.$t('btn_Delete').toString(),
      cancelText: this.$t('btn_Cancel').toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this._delCarSelected()
    })
  }

  _delCarSelected() {
    const index = this.car.items.findIndex((e) => e === this.car.selected)
    this.car.items.splice(index, 1)
    this.$set(this.car, 'selected', this.car.items[0]) // || {title: '', description: '', data: {}, base: {}}
    this.carStorageRefresh()
    this.$root.$emit('onChangeCarSelected', this.car.selected)
  }

  carStorageRefresh() {
    CarSelectorStore.refresh(this.car)
  }

  mounted() {
    const {selected = {}, items = []} = CarSelectorStore.getCars()
    this.$set(this.car, 'selected', selected)
    this.$set(this.car, 'items', items)
  }
}
