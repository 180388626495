
import {Component, Vue, Prop} from 'nuxt-property-decorator'
import {categories as CategoriesList} from '~/std/Category'

@Component({})
export default class extends Vue {
  @Prop({default: () => ''}) value!: string
  @Prop({default: () => ''}) code!: string
  @Prop({default: () => []}) items: any[] | undefined
  @Prop({default: 3}) cols: number | undefined
  @Prop({default: false}) isMobile: boolean | undefined
  @Prop({default: () => ({})}) selected!: any

  get itemClass() {
    let result = `grid-cols-${this.cols} `
    if (this.isMobile) result = result + 'gap-2'
    else result = result + 'gap-4'
    return result
  }

  get category() {
    return this.selected?.data?.find((v: any) => v.key === 'category')
  }

  getLink(value: any) {
    if (!this.category) return
    const _category = CategoriesList.list.find((c) => c.routeParams.id == this.category?.data.id)
    const codeValue = { key: this.code, value, data: value }
    const _selected = JSON.parse(JSON.stringify(this.selected ? this.selected : { data: [] }));
    let flagIsFinded = false;
    for (const s of _selected.data) {
      if (s.key === this.code) {
        flagIsFinded = true;
        s.data = codeValue.data;
      }
    }
    if (!flagIsFinded) _selected.data.push(codeValue)
    const link = _category?.getLink({}, _selected, this)
    return link;
  }
}
