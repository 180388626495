
import {Component, Vue, Prop} from 'nuxt-property-decorator'
import TabPanel from '~/components/car-selector/panel.vue'

@Component({components: {TabPanel}})
export default class extends Vue {
  @Prop({default: () => ({})}) selected!: any
  brandItems: any[] = []
  modelItems: any[] = []
  typeItems: any[] = []
  allData: Record<string, any> = {model: {}, type: {}}
  activeTab = 'brand'
  isReady = false
  cols = {
    brand: 6,
    model: 6,
    type: 4,
  }

  tabs = [
    {
      code: 'brand',
      label: '1',
      display: true,
      disabled: false,
      data: {key: '', value: '', base: {} as Record<string, any>},
    },
    {
      code: 'model',
      label: '2',
      display: true,
      disabled: false,
      data: {key: '', value: '', base: {} as Record<string, any>},
    },
    {
      code: 'type',
      label: '3',
      display: true,
      disabled: false,
      data: {key: '', value: '', base: {} as Record<string, any>},
    },
  ]

  currentParams: any = {}

  async fetch() {
    await this.loadBrands()
    await this.init()
  }

  get category() {
    return this.selected?.data?.find((v: any) => v.key === 'category')
  }

  async loadBrands() {
    if (this.allData.brand != null) return
    try {
      const params: Record<string, any> = {}
      if (this.category) {
        params.category = this.category.data.id
      }
      if (this.currentParams.brand !== JSON.stringify(params)) {
        this.$set(this.currentParams,'brand', JSON.stringify(params))
        const {data} = await this.$axios.get('categories/manufacturers', {params});
        this.$set(this.allData,'brand', data)
      }
      let selectedValue: Record<string, any>
      if (this.selected?.data)
        for (const sValue of this.selected.data) {
          if (sValue.key === 'brand') selectedValue = sValue.data;
        }
      const _brandItems = this.allData?.brand?.map((v: any) => {
        const typeCar = v.type_car_id ? v.type_car_id - 1 : v.type_car_code;
        let value = v.manufacturer_name
        if (`${typeCar}` === '0') {
          value += ` (${this.$t('truck')})`.toLocaleUpperCase();
        }
        if (selectedValue && selectedValue?.slug === v.manufacturer_name_translit) {
          selectedValue.slug = v.manufacturer_name_translit
          selectedValue.value = value
          selectedValue.base = v
        }
        return {key: v.id, value, slug: v.manufacturer_name_translit, base: v}
      })
      this.$set(this, 'brandItems', _brandItems);
    } catch (e) {
      console.error(e)
    }
  }

  async loadModels() {
    if (this.allData.model[this.tabs[0].data.key] === undefined) {
      const id = this.selected?.data?.find((e: any) => e.key === 'brand')?.data?.key
      try {
        const params: Record<string, any> = {
          manufacturer: this.tabs[0].data.base?.id || id
        }
        if (this.category) {
          params.category = this.category.data.id
        }
        if (this.currentParams.models !== JSON.stringify(params)) {
          this.currentParams.models = JSON.stringify(params)
          const {data} = await this.$axios.get('categories/models', {params});
          this.allData.model[this.tabs[0].data.key] = data;
        }
      } catch (e) {
        console.error(e)
      }
    }
    let selectedValue: Record<string, any>
    if (this.selected?.data)
      for (const sValue of this.selected.data) {
        if (sValue.key === 'model') selectedValue = sValue.data;
      }
    // let currentValue = ''
    if (this.allData.model[this.tabs[0].data.key]) {
      const _modelItems = this.allData.model[this.tabs[0].data.key].map((v: any) => {
        let value = v.model_name
        if (v.year) {
          const year = v.year.trim().split('...')
          value += ' ' + year[0]
          if (year.length === 2 && year[1]) value += ' - ' + year[1]
          else value += ` - ${this.$t('component.car_selector.tabs.time')}`
        }
        if (selectedValue && selectedValue?.slug == v.model_name_translit) {
          selectedValue.slug = v.model_name_translit
          selectedValue.value = value
          selectedValue.base = v
        }
        // if (this.tabs[1].data.key && this.tabs[1].data.key === v.id) currentValue = value
        return {key: v.code_model_1c, value, slug: v.model_name_translit, base: v}
      });
      this.$set(this, 'modelItems', _modelItems);
    }
  }

  async loadTypes() {
    if (this.allData.type[this.tabs[1].data.key] === undefined) {
      try {
        const params: Record<string, any> = {
          manufacturer: this.tabs[0].data.base?.id,
          model: this.tabs[1].data.base?.code_model_1c,
        }
        if (this.category) {
          params.category = this.category.data.id
        }
        if (params.manufacturer === undefined || params.model === undefined) {
          return;
        }
        if (this.currentParams.types !== JSON.stringify(params)) {
          console.log('loadTypes',this.currentParams)
          this.currentParams.types = JSON.stringify(params)
          const {data} = await this.$axios.get('categories/modifications', {params});
          this.allData.type[this.tabs[1].data.key] = data;
        }
      } catch (e) {
        console.error(e)
      }
    }
    let selectedValue: Record<string, any>
    if (this.selected?.data)
      for (const sValue of this.selected.data) {
        if (sValue.key === 'type') selectedValue = sValue.data;
      }
    const typeItems = this.allData.type[this.tabs[1].data.key]?.map((v: any) => {
      const start = `${v.date_start}`.slice(0, 4)
      const end = `${v.date_end}`.slice(0, 4)
      const yearStr = v.date_start ? ` (${start}-${(v.date_end && end) || '...'})` : ''
      const value = `${v.header}${yearStr}, ${v.hp}ЛС, ${v.kw}кВт`
      if (selectedValue && selectedValue?.slug == v.slug) {
        selectedValue.slug = v.slug
        selectedValue.value = value
        selectedValue.base = v
      }
      return {key: v.type_id, value, slug: v.slug, base: v}
    })
    this.$set(this, 'typeItems', typeItems || []);
  }

  items(code: string) {
    switch (code) {
      case 'brand':
        return this.brandItems
      case 'model':
        return this.modelItems
      case 'type':
        return this.typeItems
      default:
        return []
    }
  }

  getSelectedForType(code: string) {
    const result: Record<string, any> = {data: []}
    if (!this.selected || !this.selected.data) {
      return result
    }
    for(const val of this.selected.data) {
      if(code === "brand" && !['brand','category'].includes(val.key)) continue;
      if (code === "model" && val.key === "type") {
        continue;
      }
      result.data.push(val)
    }
    // console.log('getSelectedForType', code, result);
    return result
  }

  async onInput(index: number, v: any) {
    const code = this.activeTab
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-return-assign
    this.tabs.filter((e, i) => i > index).forEach((e) => e.data.value = '')
    const tabCode = this.tabs[index].code
    this.tabs[index].data.key = v.key
    this.tabs[index].data.value = v.value
    let withTypes = this.tabs[0].data.value ? this.tabs[0].data.base?.type_brand_id === 1 : true
    switch (tabCode) {
      case 'brand':
        this.$set(this.tabs[1], 'data', {key: '', value: '', base: {} as Record<string, any>})
        this.$set(this.tabs[2], 'data', {key: '', value: '', base: {} as Record<string, any>})
        this.$set(this, 'modelItems', [])
        this.$set(this, 'typeItems', [])
        this.tabs[index].data.base = this.allData[tabCode]?.find((f: any) => f.id === parseInt(v.key))

        if (this.tabs[index].data.key && !this.tabs[index].data.value && this.tabs[index].data.base) {
          this.tabs[index].data.value = this.brandItems.find((b) => b.key == this.tabs[index].data.base.id)?.value
          if (!this.tabs[index].data.value) this.tabs[index].data.value = this.tabs[index].data.base.manufacturer_name
          this.tabs[index].display = true
          this.tabs[index].disabled = false
          this.activeTab = 'model'
        }
        await this.loadModels()
        if (this.activeTab !== tabCode && !this.modelItems.length) {
          this.activeTab = code
        }
        break
      case 'model':
        this.$set(this.tabs[2], 'data', {key: '', value: '', base: {} as Record<string, any>})
        this.$set(this, 'typeItems', [])
        this.tabs[index].data.base = this.allData[tabCode][this.tabs[0].data.key]?.find((f: any) => `${f.code_model_1c}` === `${v.key}`)
        if (this.tabs[index].data.key && !this.tabs[index].data.value) {
          const current = this.modelItems.find((v) => `${v.key}` === `${this.tabs[index].data.key}`)
          this.tabs[index].data.value = current?.value
          this.tabs[index].display = true
          this.tabs[index].disabled = false
          if (withTypes) this.activeTab = 'type'
        }
        if (withTypes) {
          await this.loadTypes()
          this.tabs[index + 1].disabled = !this.typeItems.length
          if (this.tabs[index + 1].disabled) withTypes = false
          else if (this.activeTab !== tabCode && this.tabs[index + 1].disabled) this.activeTab = code
        }
        break
      case 'type':
        this.tabs[index].data.base = this.allData[tabCode][this.tabs[1].data.key]?.find((f: any) => `${f.type_id}` === `${v.key}`)
        if (this.tabs[index].data.key && !this.tabs[index].data.value) {
          const current = this.typeItems.find((v) => `${v.key}` === `${this.tabs[index].data.key}`)
          this.tabs[index].data.value = current?.value
          this.tabs[index].display = true
          this.tabs[index].disabled = false
        }
        break
    }
    const tabLength = withTypes ? this.tabs.length - 1 : this.tabs.length - 2
    const params = {
      data: JSON.parse(JSON.stringify(this.tabs.map((e) => ({key: e.code, data: e.data})))),
      isChanged: false
    }
    if (v) {
      if (index === tabLength) {
        params.isChanged = true
        if (this.isReady) this.$emit('changed', params)
      } else {
        const code = this.tabs[index + 1]?.code
        if (this.items(code).length) {
          this.activeTab = code
        }
      }
    }
    this.$emit('input', this.tabs.map((e) => ({key: e.code, data: e.data})))
  }

  async init() {
    this.isReady = false
    for (const i in this.tabs) {
      const tab = this.tabs[i]
      const data = this.selected?.data?.find((item: { key: string }) => item.key === tab.code)
      tab.label = this.$t(`component.car_selector.tabs.${tab.code}`).toString()
      if (data?.data?.key) {
        await this.onInput(parseInt(i), data.data)
      }
    }
    this.isReady = true
  }

  async created() {
    await this.loadBrands()
    await this.init();
  }

  mounted() {
    setTimeout(() => {
      const e: any = document.querySelector(`.tab--${this.activeTab}`) || {}
      if (e.style) e.style = ''
    }, 1000)
  }
}

